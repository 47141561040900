import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { UserListReducer } from "./reducers/usersReducer";
import { getAuthData } from "./actions/auth";
import {
  adminLoginReducer,
  adminLogoutReducer,
  adminPasswordResetLinkReducer,
  adminPasswordResetReducer,
} from "./reducers/adminUserReducer";
import {
  listOrderReducer,
  listOrderPickurReducer,
  detailOrderReducer,
  updateOrderReducer,
  listTelleCallerReducer,
  mainOrderListReducer,
} from "./reducers/pickupOrderReducer";
import {
  detailDeviceReducer,
  updateDeviceReducer,
  deviceColorListReducer,
} from "./reducers/deviceReportReducer";
import {
  updateSellerInfoReducer,
  documentListReducer,
  crmInfoListReducer,
  crmInfoDetailReducer,
  updateCRMReducer,
} from "./reducers/sellerInfoReducer";

const reducer = combineReducers({
  adminLogin: adminLoginReducer,
  adminLogout: adminLogoutReducer,
  adminPasswordResetLink: adminPasswordResetLinkReducer,
  adminPasswordReset: adminPasswordResetReducer,

  userList: UserListReducer,

  orderList: listOrderReducer,
  orderDetail: detailOrderReducer,
  orderUpdate: updateOrderReducer,

  mainOrderList: mainOrderListReducer,

  pickupOrderList: listOrderPickurReducer,
  tellecallerList: listTelleCallerReducer,

  detailDevice: detailDeviceReducer,
  updateDevice: updateDeviceReducer,
  deviceColors: deviceColorListReducer,

  updateSellerInfo: updateSellerInfoReducer,
  sellerDocumentList: documentListReducer,
  crmInfoList: crmInfoListReducer,
  crmInfoDetail: crmInfoDetailReducer,
  updateCRM: updateCRMReducer,
});

const adminInfoFromStorage = getAuthData();

const initialState = {
  adminLogin: {
    admin: adminInfoFromStorage,
    loading: false,
    error: null,
    success: adminInfoFromStorage ? true : undefined,
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
