import axios from "axios";
import { BASE_URL } from "./apiConfig";
import {
  SELLER_INFO_UPDATE_REQUEST,
  SELLER_INFO_UPDATE_SUCCESS,
  SELLER_INFO_UPDATE_FAIL,
  CRM_INFO_LIST_REQUEST,
  CRM_INFO_LIST_FAIL,
  CRM_INFO_LIST_SUCCESS,
  CRM_INFO_DETAILS_FAIL,
  CRM_INFO_DETAILS_REQUEST,
  CRM_INFO_DETAILS_SUCCESS,
  DOCUMENT_GET_LIST_REQUEST,
  DOCUMENT_GET_LIST_SUCCESS,
  DOCUMENT_GET_LIST_FAIL,
  PDF_DOWNLOAD_FAIL,
  PDF_DOWNLOAD_REQUEST,
  PDF_DOWNLOAD_SUCCESS,
  CRM_INFO_UPDATE_REQUEST,
  CRM_INFO_UPDATE_FAIL,
  CRM_INFO_UPDATE_SUCCESS,
} from "../constants/sellerInfoConstant";

export const createSellerInfoAction =
  (postData) => async (dispatch, getState) => {
    const {
      documents,
      order_id,
      final_price,
      execitive_id,
      tellecaller_id,
      state,
      city,
      location,
      status,
      imei_1,
      imei_2,
    } = postData;
    try {
      dispatch({ type: SELLER_INFO_UPDATE_REQUEST });
      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/v1/crm/create_seller/order_info/`,
        {
          documents: documents,
          order_id: order_id,
          final_price: final_price,
          execitive_id: execitive_id,
          tellecaller_id: tellecaller_id,
          state: state,
          city: city,
          imei_1: imei_1,
          imei_2: imei_2,
          location: location,
          status: status,
        },
        config
      );

      dispatch({ type: SELLER_INFO_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SELLER_INFO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const createCustomerCRMAction =
  (postData) => async (dispatch, getState) => {
    const {
      documents,
      aadhaar,
      order_id,
      final_price,
      location,
      ram,
      storage,
      brand,
      product,
      email,
      imei,
      user,
      phone_number,
      warrenty,
      dob,
      payment,
      upi,
      device_color,
      signature,
    } = postData;
    try {
      dispatch({ type: SELLER_INFO_UPDATE_REQUEST });
      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/v1/crm/create_seller/customer_crm_info/`,
        {
          documents: documents,
          pick_up_order: order_id,
          final_price: Number(final_price),
          customer_address: location,
          ram: ram,
          storage: storage,
          product_model: product,
          product_brand: brand,
          imei_num: imei,
          name_on_document: user,
          customer_mobile: phone_number,
          warranty: warrenty["label"],
          aadhaar: aadhaar,
          dob: dob,
          device_color: device_color,
          payment_model: payment["value"],
          upi_id: upi,
          email: email,
          signature: signature,
        },
        config
      );

      dispatch({ type: SELLER_INFO_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SELLER_INFO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

  export const createCustomerCRMAction1 =
  (FormData) => async (dispatch, getState) => {
    
    try {
      dispatch({ type: SELLER_INFO_UPDATE_REQUEST });
      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/v1/crm/create_seller/customer_crm_info/`,
        FormData,
        config
      );

      dispatch({ type: SELLER_INFO_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SELLER_INFO_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const crmInfoListAction =
  (getOrderParams) => async (dispatch, getState) => {
    try {
      dispatch({ type: CRM_INFO_LIST_REQUEST });

      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };
      let endpointURL = `/api/v1/crm/customer_crm_list`;
      const { pageNumber } = getOrderParams;

      if (pageNumber) {
        endpointURL += `/?page=${pageNumber}`;
      }
      const { data } = await axios.get(endpointURL, config);

      dispatch({ type: CRM_INFO_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CRM_INFO_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const crmInfoDetailAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: CRM_INFO_DETAILS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(
      `/api/v1/crm/customer_crm_details/${id}`,
      config
    );

    dispatch({ type: CRM_INFO_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CRM_INFO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const updateCRMAction = (id, reason) => async (dispatch, getState) => {
  try {
    dispatch({ type: CRM_INFO_UPDATE_REQUEST });
    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };

    const { data } = await axios.patch(
      `/api/v1/crm/update_customer_crm/${id}/`,
      {
        reason: reason["value"],
      },
      config
    );

    dispatch({ type: CRM_INFO_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CRM_INFO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
export const sellerDocumentListAction = () => async (dispatch) => {
  try {
    dispatch({ type: DOCUMENT_GET_LIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/v1/crm/document_list/`, config);

    dispatch({ type: DOCUMENT_GET_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DOCUMENT_GET_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const downloadPdfAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: PDF_DOWNLOAD_REQUEST });

    const response = await axios({
      url: `/api/v1/crm/customer_order_crm_pdf/${id}/`,
      method: "GET",
      responseType: "blob", // Important for handling file downloads
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Sale_Agreement_${id}.pdf`; // Filename for download
    document.body.appendChild(link);
    link.click(); // Trigger download
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href); // Release the object URL

    dispatch({ type: PDF_DOWNLOAD_SUCCESS });
  } catch (error) {
    dispatch({
      type: PDF_DOWNLOAD_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
