import {
    USERS_LIST_REQUEST,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAIL
} from '../constants/userConstant'


export const UserListReducer = (state = { users: [] }, action) => {

    switch (action.type) {
        case USERS_LIST_REQUEST:
            return { loading: true, users: [] }

        case USERS_LIST_SUCCESS:
            return { loading: false, success: true, users: action.payload}

        case USERS_LIST_FAIL:
            return { loading: false, error: action.payload}

        default:
            return state;
    }
}