import {
  UilEstate,
  UilClipboardAlt,
  UilUsersAlt,
  UilMoneyBill,
} from "@iconscout/react-unicons";

export const SidebarData = (admin) => {
  const sidebarMenu = [
    {
      icon: UilEstate,
      heading: "Dashboard",
      key: "/",
    },
    {
      icon: UilClipboardAlt,
      heading: "CRM List",
      key: "/crm/",
    },
    {
      icon: UilMoneyBill,
      heading: "Evaluation",
      key: "/crm/price_evaluation",
    },
  ];

  if (admin && admin.isAdmin) {
    sidebarMenu.splice(1, 0, {
      icon: UilUsersAlt,
      heading: "Users",
      key: "/crm/users",
    });
  }

  return sidebarMenu;
};

export const status = [
  { label: "Un Finished", value: "Inquiries" },
  { label: "In Progress", value: "In Progress" },
  { label: "Assigned", value: "Assigned For PickUp" },
  { label: "Picked Up", value: "Picked Up" },
  { label: "Completed", value: "Completed" },
  { label: "Cancelled", value: "Cancelled" },
];

export const DOCUMENT_TYPE = [
  { label: "Customer Photo", value: "Customer Photo" },
  { label: "IMEI Photo", value: "IMEI Photo" },
  { label: "Invoice Photo", value: "Invoice Photo" },
];

export const WARRANTY_LIST = [
  { label: "0-3 Months", value: "0-3 Months" },
  { label: "3-6 Months", value: "3-6 Months" },
  { label: "6-11 Months", value: "6-11 Months" },
  { label: "More than 11 months", value: "More than 11 months" },
  { label: "Less than 11 months Or Out of warranty", value: "Less than 11 months Or Out of warranty" },
  { label: "Less than 1 year", value: "Less than 1 year" },
  { label: "More than 1 year", value: "More than 1 year" },
  { label: "More than 2 year", value: "More than 2 year" },
  { label: "More than 3 years", value: "More than 3 years" },
];

export const PAYMENTOPTIONS = [
  { value: "Google Pay", label: "Google Pay" },
  { value: "PhonePay", label: "PhonePay" },
  { value: "PayTm", label: "PayTm" },
  { value: "Bank Transfer", label: "Bank Transfer" },
  { value: "other", label: "Other UPI ID" },
];

export const RETURNOPTIONS = [
  { value: "Apple ID Issues", label: "Apple ID Issues" },
  { value: "Samsung Account Issues", label: "Samsung Account Issues" },
  { value: "MI Account Issues", label: "MI Account Issues" },
  { value: "Legality Issues", label: "Legality Issues" },
  { value: "Customer Personal Reason", label: "Customer Personal Reason" },
  { value: "Others", label: "Others" },
];