import React from "react";
import { MDBInput, MDBTextArea } from "mdb-react-ui-kit";
import { Controller } from "react-hook-form";
import { Typography } from "antd";
import Select from "react-select";
import { PAYMENTOPTIONS } from "../../Data/Data";
import { WARRANTY_LIST } from "../../Data/Data";
import "./CustomerInfoInput.scss";

function CustomerInfoInput({ order_detail, control, errors }) {
  return (
    <>
      {order_detail && (
        <div className="order-form-input">
          <Typography.Title level={4}>Basic Information</Typography.Title>
          <div className="basic-information">
            <div className="user">
              <Controller
                control={control}
                id="user"
                name="user"
                defaultValue={order_detail?.user}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.user ? "input-error" : ""}`}
                    type="text"
                    label="User"
                    {...field}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className="phone-number">
              <Controller
                control={control}
                id="phone_number"
                name="phone_number"
                defaultValue={order_detail?.phone_number}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${
                      errors.phone_number ? "input-error" : ""
                    }`}
                    maxLength={15}
                    type="tel"
                    label="Phone Number"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="dob">
              <Controller
                control={control}
                id="dob"
                name="dob"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.dob ? "input-error" : ""}`}
                    type="date"
                    label="Date of Birth"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="email">
              <Controller
                control={control}
                id="email"
                name="email"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.email ? "input-error" : ""}`}
                    type="email"
                    label="Customer Email"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <Typography.Title level={4}>
            Customer Payment Information
          </Typography.Title>

          <div className="payment-info">
            <div className="payment-mode">
              <Controller
                control={control}
                id="payment"
                name="payment"
                render={({ field }) => (
                  <Select
                    isClearable={true}
                    options={PAYMENTOPTIONS}
                    placeholder="Select Payment Mode.."
                    menuPortalTarget={document.body}
                    {...field}
                    onChange={(value) => field.onChange(value)}
                    className={errors.payment ? "input-error" : ""}
                  />
                )}
              />
            </div>

            <div className="payment-upi">
              <Controller
                control={control}
                id="upi"
                name="upi"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.upi ? "input-error" : ""}`}
                    type="text"
                    label="UPI ID"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <Typography.Title level={4}>Device Information</Typography.Title>

          <div className="device-information">
            <div className="product">
              <Controller
                control={control}
                id="product"
                name="product"
                defaultValue={order_detail?.product}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.product ? "input-error" : ""}`}
                    type="text"
                    label="Product"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="brand">
              <Controller
                control={control}
                id="brand"
                name="brand"
                defaultValue={order_detail?.brand_name}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.brand ? "input-error" : ""}`}
                    type="text"
                    label="Brand"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="warrenty">
              <Controller
                control={control}
                id="warrenty"
                name="warrenty"
                render={({ field }) => (
                  <Select
                    isClearable={true}
                    options={WARRANTY_LIST}
                    placeholder="Select Warranty.."
                    menuPortalTarget={document.body}
                    {...field}
                    onChange={(value) => field.onChange(value)}
                    className={errors.warrenty ? "input-error" : ""}
                  />
                )}
              />
            </div>

            <div className="ram">
              <Controller
                control={control}
                id="ram"
                name="ram"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.ram ? "input-error" : ""}`}
                    type="text"
                    label="Ram"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>

            <div className="storage">
              <Controller
                control={control}
                id="storage"
                name="storage"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.storage ? "input-error" : ""}`}
                    type="text"
                    label="Storage"
                    maxLength={50}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="imei">
              <Controller
                control={control}
                id="imei"
                name="imei"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${errors.imei ? "input-error" : ""}`}
                    type="text"
                    maxLength={50}
                    label="IMEI"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="device-color">
              <Controller
                control={control}
                id="device_color"
                name="device_color"
                defaultValue=""
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${
                      errors.device_color ? "input-error" : ""
                    }`}
                    type="text"
                    maxLength={50}
                    label="Device Color"
                    {...field}
                  />
                )}
              />
            </div>
          </div>

          <Typography.Title level={4}>Device Cost</Typography.Title>
          <div className="price-related-input">
            <div className="final-price">
              <Controller
                control={control}
                id="final_price"
                name="final_price"
                defaultValue={order_detail?.final_price}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass={`mb-4 ${
                      errors.final_price ? "input-error" : ""
                    }`}
                    type="text"
                    maxLength={50}
                    label="Device Cost"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <Typography.Title level={4}>Pickup Address</Typography.Title>
          <div className="pickup-input">
            <div className="location">
              <Controller
                control={control}
                id="pick_up_location"
                name="pick_up_location"
                defaultValue={order_detail?.pick_up_location}
                render={({ field }) => (
                  <MDBTextArea
                    wrapperClass={`mb-4 ${
                      errors.pick_up_location ? "input-error" : ""
                    }`}
                    label="Pickup Location"
                    rows={3}
                    maxLength={200}
                    {...field}
                    value={field.value || ""} 
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerInfoInput;
