import React from "react";
import { Typography } from "antd";
import { MDBCol, MDBRow, MDBInput } from "mdb-react-ui-kit";
import { Controller } from "react-hook-form";
import Select from "react-select";
import "./MainOrderFormInput.scss";

export default function MainOrderFormInput({
  device_colors,
  order_detail,
  control,
}) {
  return (
    <>
      <div className="mainOrderFormInput">
        <Typography.Title level={4}>Basic Information</Typography.Title>
        <div className="basicInformation">
          <div className="id">
            <MDBInput
              wrapperClass="mb-4"
              type="text"
              value={order_detail?.id || ""}
              label="id"
              disabled
            />
          </div>
          <div className="user">
            <MDBInput
              wrapperClass="mb-4"
              type="text"
              value={order_detail?.user || ""}
              label="user"
              disabled
            />
          </div>

          <div className="email">
            <MDBInput
              wrapperClass="mb-4"
              type="email"
              value={order_detail?.email || ""}
              label="email"
              name="email"
              disabled
            />
          </div>

          <div className="phone-number">
            <MDBInput
              wrapperClass="mb-4"
              type="tel"
              value={order_detail?.phone_number || ""}
              label="Phone Number"
              name="phone_number"
              disabled
            />
          </div>
        </div>

        <Typography.Title level={4}>Device Information</Typography.Title>

        <div className="deviceInformaition">
          <div className="product">
            <MDBInput
              wrapperClass="mb-4"
              type="text"
              value={order_detail?.product || ""}
              label="Product"
              disabled
            />
          </div>

          <div className="variant">
            <MDBInput
              wrapperClass="mb-4"
              type="text"
              value={order_detail?.variant || ""}
              label="Variant"
              disabled
            />
          </div>

          <div className="warrenty">
            <MDBInput
              wrapperClass="mb-4"
              type="text"
              value={order_detail?.warranty || ""}
              label="Warranty"
              disabled
            />
          </div>

          <div className="device-color">
            <Controller
              control={control}
              id="device_color"
              name="device_color"
              render={({ field }) => (
                <Select
                  isClearable={true}
                  options={device_colors}
                  placeholder="select colors..."
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  value={field.value}
                  {...field}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>

          <div className="imei-1">
            <Controller
              control={control}
              id="imei_1"
              name="imei_1"
              render={({ field }) => (
                <MDBInput
                  wrapperClass="mb-4"
                  type="text"
                  label="IMEI-1"
                  value={field.value || ""}
                  {...field}
                />
              )}
            />
          </div>

          <div className="imei-2">
            <Controller
              control={control}
              id="imei_2"
              name="imei_2"
              render={({ field }) => (
                <MDBInput
                  wrapperClass="mb-4"
                  type="text"
                  label="IMEI-2"
                  value={field.value || ""}
                  {...field}
                />
              )}
            />
          </div>
        </div>

        <Typography.Title level={4}>Product Cost</Typography.Title>

        <div className="productCost">
          <MDBRow>
            <MDBCol>
              <Controller
                control={control}
                id="final_price"
                name="final_price"
                render={({ field }) => (
                  <MDBInput
                    wrapperClass="mb-4"
                    type="number"
                    value={field.value || ""}
                    label="Device Cost"
                    {...field}
                  />
                )}
              />
            </MDBCol>
          </MDBRow>
        </div>
      </div>
    </>
  );
}
