export const DEVICE_REPORT_DETAIL_REQUEST = "DEVICE_REPORT_DETAIL_REQUEST"
export const DEVICE_REPORT_DETAIL_SUCCESS = "DEVICE_REPORT_DETAIL_SUCCESS"
export const DEVICE_REPORT_DETAIL_FAIL = "DEVICE_REPORT_DETAIL_FAIL"
export const DEVICE_REPORT_DETAIL_RESET = "DEVICE_REPORT_DETAIL_RESET"

export const DEVICE_REPORT_UPDATE_REQUEST = "DEVICE_REPORT_UPDATE_REQUEST"
export const DEVICE_REPORT_UPDATE_SUCCESS = "DEVICE_REPORT_UPDATE_SUCCESS"
export const DEVICE_REPORT_UPDATE_FAIL = "DEVICE_REPORT_UPDATE_FAIL" 
export const DEVICE_REPORT_UPDATE_RESET = "DEVICE_REPORT_UPDATE_RESET"

export const DEVICE_COLOR_LIST_REQUEST = "DEVICE_COLOR_LIST_REQUEST"
export const DEVICE_COLOR_LIST_SUCCESS = "DEVICE_COLOR_LIST_SUCCESS"
export const DEVICE_COLOR_LIST_FAIL = "DEVICE_COLOR_LIST_FAIL"
export const DEVICE_COLOR_LIST_RESET = "DEVICE_COLOR_LIST_RESET"
