import {
    DEVICE_REPORT_DETAIL_FAIL,
    DEVICE_REPORT_DETAIL_REQUEST,
    DEVICE_REPORT_DETAIL_SUCCESS,

    DEVICE_REPORT_UPDATE_REQUEST,
    DEVICE_REPORT_UPDATE_SUCCESS,
    DEVICE_REPORT_UPDATE_FAIL,

    DEVICE_COLOR_LIST_FAIL,
    DEVICE_COLOR_LIST_REQUEST,
    DEVICE_COLOR_LIST_SUCCESS,
    DEVICE_COLOR_LIST_RESET,

} from '../constants/deviceReportConstant'


export const deviceColorListReducer = (state = { device_colors: [] }, action) => {

    switch (action.type) {
        case DEVICE_COLOR_LIST_REQUEST:
            return { loading: true, device_colors: [] }

        case DEVICE_COLOR_LIST_SUCCESS:
            return { loading: false, success: true, device_colors: action.payload }

        case DEVICE_COLOR_LIST_FAIL:
            return { loading: false, error: action.payload }

        case DEVICE_COLOR_LIST_RESET:
            return { device_colors: [] }

        default:
            return state
    }
}

export const detailDeviceReducer = (state = {}, action) => {

    switch (action.type) {
        case DEVICE_REPORT_DETAIL_REQUEST:
            return { loading: true }

        case DEVICE_REPORT_DETAIL_SUCCESS:
            return { loading: false, success: true, device_detail: action.payload }

        case DEVICE_REPORT_DETAIL_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const updateDeviceReducer = (state = {}, action) => {

    switch (action.type) {
        case DEVICE_REPORT_UPDATE_REQUEST:
            return { loading: true }

        case DEVICE_REPORT_UPDATE_SUCCESS:
            return { loading: false, success: true, order_update: action.payload }

        case DEVICE_REPORT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

            default:
                return state
    }
}