import React from "react";
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";
import "./SearchBar.scss";

export const SearchBar = ({ search_params, setSearchParams, onClickBtn }) => {
  return (
    <>
      <div className="search-bar">
        <div className="searchInput">
          <MDBInput
            wrapperClass="mb-2"
            name="search"
            label="search"
            type="text"
            value={search_params}
            onChange={(e) => setSearchParams(e.target.value)}
          />
          <MDBBtn className="searchBtn" onClick={onClickBtn}>
            Search
          </MDBBtn>
        </div>
      </div>
    </>
  );
};
