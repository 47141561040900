export const SELLER_INFO_UPDATE_REQUEST = 'SELLER_INFO_UPDATE_REQUEST'
export const SELLER_INFO_UPDATE_SUCCESS = 'SELLER_INFO_UPDATE_SUCCESS'
export const SELLER_INFO_UPDATE_FAIL = 'SELLER_INFO_UPDATE_FAIL'
export const SELLER_INFO_UPDATE_RESET = 'SELLER_INFO_UPDATE_RESET'

export const DOCUMENT_GET_LIST_REQUEST = 'DOCUMENT_GET_LIST_REQUEST'
export const DOCUMENT_GET_LIST_SUCCESS = 'DOCUMENT_GET_LIST_SUCCESS'
export const DOCUMENT_GET_LIST_FAIL = 'DOCUMENT_GET_LIST_FAIL'
export const DOCUMENT_GET_LIST_RESET = 'DOCUMENT_GET_LIST_RESET'

export const CRM_INFO_LIST_REQUEST = 'CRM_INFO_LIST_REQUEST'
export const CRM_INFO_LIST_SUCCESS = 'CRM_INFO_LIST_SUCCESS'
export const CRM_INFO_LIST_FAIL = 'CRM_INFO_LIST_FAIL'
export const CRM_INFO_LIST_RESET = 'CRM_INFO_LIST_RESET'


export const CRM_INFO_DETAILS_REQUEST = 'CRM_INFO_DETAILS_REQUEST'
export const CRM_INFO_DETAILS_SUCCESS = 'CRM_INFO_DETAILS_SUCCESS'
export const CRM_INFO_DETAILS_FAIL = 'CRM_INFO_DETAILS_FAIL'
export const CRM_INFO_DETAILS_RESET = 'CRM_INFO_DETAILS_RESET'

export const CRM_INFO_UPDATE_REQUEST = 'CRM_INFO_UPDATE_REQUEST'
export const CRM_INFO_UPDATE_SUCCESS = 'CRM_INFO_UPDATE_SUCCESS'
export const CRM_INFO_UPDATE_FAIL = 'CRM_INFO_UPDATE_FAIL'
export const CRM_INFO_UPDATE_RESET = 'CRM_INFO_UPDATE_RESET'


export const PDF_DOWNLOAD_REQUEST = 'PDF_DOWNLOAD_REQUEST';
export const PDF_DOWNLOAD_SUCCESS = 'PDF_DOWNLOAD_SUCCESS';
export const PDF_DOWNLOAD_FAIL = 'PDF_DOWNLOAD_FAIL';