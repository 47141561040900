import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Orders.scss";
import {
  ORDER_DETAIL_RESET,
  MAIN_PICKUP_ORDER_LIST_RESET,
} from "../../constants/pickupOrderConstant";

import OrderTable from "./table/OrderTable";
import Filterbar from "../../components/Filterbar/Filterbar";

export default function Orders() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ORDER_DETAIL_RESET });
    dispatch({ type: MAIN_PICKUP_ORDER_LIST_RESET });
  }, []);

  return (
    <>
      <div className="order-container">
        <div className="order-table-container">
          <OrderTable />
        </div>
        {/* <div className="filter-container ">
          <Filterbar />
        </div> */}
      </div>
    </>
  );
}
