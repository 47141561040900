import Table from "./Table/Table";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ORDER_DETAIL_RESET } from "../../constants/pickupOrderConstant";
import Cards from "./Cards/Cards";
import "./MainDash.scss";

export default function MainDash() {
  const [totalOrder, setTotalOrder] = useState(null)

  const dispatch = useDispatch();
 
  useEffect(() => {
    dispatch({ type: ORDER_DETAIL_RESET })
  }, [])

  const handleTotalOrder = (total) => {
    setTotalOrder(total)
  }

  return (
    <>
      <div className="MainDash">
        {/* <Cards totalOrder={totalOrder} /> */}
        <Table handleTotalOrder={handleTotalOrder} />
      </div>
    </> 
  );
}
