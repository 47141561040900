import React from "react";
import { Typography } from "antd";
import { MDBInput } from "mdb-react-ui-kit";
import { Controller } from "react-hook-form";
import Select from "react-select";
import "./CustomerFormInfo.scss";

export default function CustomerFormInfo({ control, document_list, seller_info_list }) {
  const { seller_information } = seller_info_list

  return (
    <>
      <div className="customer-form-info">
        <Typography.Title level={4}>Customer Detail</Typography.Title>

        {[0, 1, 2].map((index) => (
          <div key={index} className="customer-details">
            <div className="document-list">
              <Controller
                control={control}
                id={`document_${index}`}
                name={`document_${index}`}
                
                render={({ field }) => (
                  <Select
                    isClearable={true}
                    options={document_list}
                    placeholder="Please Select.."
                    defaultValue={seller_information ? document_list.find(option => option.name === seller_information[index]?.document) : ""}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    menuPortalTarget={document.body}
                    {...field}
                    onChange={(value) => field.onChange(value)}
                  />
                )}
              />
            </div>
            <div className="document-name">
              <Controller
                control={control}
                id={`document_name_${index}`}
                name={`document_name_${index}`}
                defaultValue={seller_information ? seller_information[index]?.name_on_document : ""}
                render={({ field }) => (
                  <MDBInput
                    wrapperClass="mb-4"
                    type="text"
                    label="name"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="document-image">
              <Controller
                control={control}
                id={`document_image${index}`}
                name={`document_image${index}`}
                // defaultValue={seller_information[index]?.document_image || ""}
                render={({ field }) => (
                  <input
                    type="file"
                    onChange={(e) => {
                      // const file = e.target.files[index];
                      field.onChange(e.target.files);
                    }}
                  />
                )}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
