import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  crmInfoDetailAction,
  updateCRMAction,
} from "../../actions/sellerInfoAction";
import * as yup from "yup";
import { Card, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomerUpdateInput } from "./CustomerUpdateInput";
import { MDBBtn } from "mdb-react-ui-kit";
import { PropagateLoader, ClipLoader } from "react-spinners";
import { useParams, useNavigate } from "react-router-dom";

// Define your validation schema
const schema = yup.object().shape({
  device_return: yup
    .object()
    .shape({
      value: yup.string().required("Device Return Option is required"),
    })
    .nullable()
    .required("Device Return Option is required"),
});

export const UpdateCustomerCRM = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const crmDetails = useSelector((state) => state.crmInfoDetail);
  const { crm_info_details, loading, errors: oError } = crmDetails;
  const updateCRM = useSelector((state) => state.updateCRM);
  const { loading: isLoading, success: isSuccess } = updateCRM;
  const { id } = useParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const orderSubmitForm = async (data) => {
    const reason = data["device_return"];
    if (reason) {
      dispatch(updateCRMAction(id, reason));
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/crm");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    dispatch(crmInfoDetailAction(id));
  }, [id, dispatch]);
  return (
    <>
      <div className="order-form">
        {loading && (
          <div className="loading-container">
            <PropagateLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}
        {crm_info_details && (
          <>
            <Card>
              <form onSubmit={handleSubmit(orderSubmitForm)}>
                <div className="custom-border">
                  <CustomerUpdateInput
                    crm_info_details={crm_info_details}
                    control={control}
                    errors={errors}
                  />
                </div>

                <div className="update-btton-container">
                  <MDBBtn
                    className={`${isLoading ? "dimmed-button" : ""}`}
                    type="submit"
                    style={{ height: "2.5rem", width: "6rem" }}
                    block
                  >
                    {isLoading && <ClipLoader color="#36d7b7" size={20} />}
                    Update
                  </MDBBtn>
                </div>
              </form>
            </Card>
          </>
        )}
      </div>
    </>
  );
};
