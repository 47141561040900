import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Sidebar.scss";
import Logo from "../../imgs/brand_logo.webp";
import { SidebarData } from "../../Data/Data";
import { UilSignOutAlt, UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { USER_ADMIN_LOGOUT_RESET } from "../../constants/adminUserConstants";
import { admin_logout } from "../../actions/adminUserAction";

export default function Sidebar() {
  const [selected, setSelected] = useState("");
  const [expanded, setExpanded] = useState(false);
  const adminLogin = useSelector((state) => state.adminLogin);

  const { admin } = adminLogin;
  const adminLogout = useSelector((state) => state.adminLogout);
  const { success: isAdminLogoutSuccess } = adminLogout;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const sideBarMenu = SidebarData(admin);
  const sidebarRef = useRef(null);

  const sideBarVariants = {
    true: {
      left: "0",
    },
    false: {
      left: "-60%",
    },
  };


  // logout all login users
  // useEffect(() => {
  //   if(admin) {
  //     dispatch(admin_logout())
  //   }
  // }, [admin, dispatch])


  useEffect(() => {
    if (isAdminLogoutSuccess) {
      dispatch({ type: USER_ADMIN_LOGOUT_RESET });
      navigate(`/crm/login`);
    }
  }, [isAdminLogoutSuccess, navigate, dispatch]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        event.target.className !== "bars"
      ) {
        setExpanded(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  if (!admin) {
    return null;
  }

 
  const logoutHandler = () => {
    dispatch(admin_logout());
    // window.localStorage.clear();
  };

  const toggleSidebar = (event) => {
    event.stopPropagation(); // Prevent event propagation
    setExpanded(!expanded);
  };

  const changeRoute = (key) => {
    navigate(`${key}`);
  };

  return (
    <React.Fragment>
      {!expanded && (
        <div
          className="bars"
          // style={expanded ? { left: "55%" } : { left: "5%" }}
          onClick={toggleSidebar}
        >
          <UilBars />
        </div>
      )}
      <motion.div
        className="Sidebar"
        ref={sidebarRef}
        variants={sideBarVariants}
        animate={window.innerWidth <= 480 ? `${expanded}` : ""}
      >
        {/* logo */}
        <div className="logo">
          <img src={Logo} alt="" className="logo-image" />
          <span
            style={{ color: "#002e5e", fontFamily: "'poppins',sans-serif" }}
          >
            BuyBacKart
          </span>
        </div>

        {/* menu */}
        <div className="menu">
          {sideBarMenu.map((item, index) => {
            return (
              <div
                className={
                  selected === item.key ? "menuItem active" : "menuItem"
                }
                onClick={() => changeRoute(item.key)}
                key={index}
              >
                <item.icon />
                <span>{item.heading}</span>
              </div>
            );
          })}
        </div>
        <div className="sign-out-container" onClick={logoutHandler}>
          <UilSignOutAlt />
        </div>
      </motion.div>
    </React.Fragment>
  );
}
