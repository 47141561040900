import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import { ClipLoader } from "react-spinners";
import classnames from "classnames";
import { adminPasswordResetEmailAction } from "../../actions/adminUserAction";
import { Form } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  ADMIN_PASSWORD_LINK_RESET,
  USER_ADMIN_LOGOUT,
} from "../../constants/adminUserConstants";
import "react-toastify/dist/ReactToastify.css";
import "./AdminForgotPassword.scss";

export default function AdminForgotPasswordEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumber, setPhonenumber] = useState("");
  const [error, setError] = useState("");
  const adminPasswordResetLink = useSelector(
    (state) => state.adminPasswordResetLink
  );
  const {
    reset_link,
    loading: resetLoading,
    error: rError,
  } = adminPasswordResetLink;
  // const { massage } = reset_link

  const handleInputChange = (event) => {
    const { value } = event.target;
    setPhonenumber(value);
    setError("");
  };

  useEffect(() => {
    if (reset_link) {
      toast.info(reset_link.message);

      setTimeout(() => {
        navigate("/login/", { replace: true });
        dispatch({ type: USER_ADMIN_LOGOUT });
      }, 5000);
    }
    if (rError) {
      toast.error(rError);
    }
    dispatch({ type: ADMIN_PASSWORD_LINK_RESET });
  }, [reset_link, rError, navigate, dispatch]);

  const validateForm = () => {
    let isValid = true;
    if (phoneNumber.trim() === "") {
      setError("please select email field");
      isValid = false;
    }
    return isValid;
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (validateForm()) {
      dispatch(adminPasswordResetEmailAction(phoneNumber));
    }
  };

  return (
    <>
      <MDBContainer fluid>
        <div
          className="forgot-password-container"
          style={{
            backgroundImage:
              "url(https://buybackart-s3-media-storage.s3.ap-south-1.amazonaws.com/static/extras/35055371_8270289.webp)",
            height: "100vh",
            width: "100vw",
            backgroundSize: "cover",
            marginLeft: "-12px",
          }}
        >
          <MDBCard>
            <MDBCardBody className="card-container">
              <h3>
                Enter Your Registered <br /> Phone Number
              </h3>
              <Form onSubmit={submitForm}>
                <div className="input-container">
                  <MDBInput
                    className={classnames("react-select", {
                      "is-invalid": error,
                    })}
                    wrapperClass="mb-4"
                    name="phone_number"
                    label="Phone"
                    value={phoneNumber}
                    type="text"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="btn-container">
                  {resetLoading && (
                    <div style={{ display: "flex", margin: "0px" }}>
                      <ClipLoader color="#36d7b7" size={25} />
                    </div>
                  )}

                  <MDBBtn>Send</MDBBtn>
                </div>
              </Form>
            </MDBCardBody>
          </MDBCard>
        </div>
      </MDBContainer>

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}
