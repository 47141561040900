import { 
    USER_ADMIN_LOGIN_REQUEST,
    USER_ADMIN_LOGIN_SUCCESS,
    USER_ADMIN_LOGIN_FAIL,
    USER_ADMIN_LOGIN_RESET,
    USER_ADMIN_LOGOUT,

    ADMIN_PASSWORD_RESET_LINK_REQUEST,
    ADMIN_PASSWORD_RESET_LINK_SUCCESS,
    ADMIN_PASSWORD_RESET_LINK_FAIL,
    ADMIN_PASSWORD_LINK_RESET,

    ADMIN_PASSWORD_RESET_REQUEST,
    ADMIN_PASSWORD_RESET_SUCCESS,
    ADMIN_PASSWORD_RESET_FAIL,
    ADMIN_PASSWORD_RESET,

    USER_ADMIN_LOGOUT_FAIL,
    USER_ADMIN_LOGOUT_SUCCESS,
    USER_ADMIN_LOGOUT_RESET,
    USER_ADMIN_LOGOUT_REQUEST
 } from "../constants/adminUserConstants";


 export const adminLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_ADMIN_LOGIN_REQUEST:
            return { loading: true }

        case USER_ADMIN_LOGIN_SUCCESS:
            return { loading: false, success: true, admin: action.payload }

        case USER_ADMIN_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_ADMIN_LOGIN_RESET:
            return {}
        
        default:
            return state
    }
 }


 export const adminLogoutReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_ADMIN_LOGOUT_REQUEST:
            return { loading: true }

        case USER_ADMIN_LOGOUT_SUCCESS:
            return { loading: false, success: true }

        case USER_ADMIN_LOGOUT_FAIL:
            return { loading: false, error: action.payload }

        case USER_ADMIN_LOGOUT_RESET:
            return {}
        
        default:
            return state
    }
 }

 export const adminPasswordResetLinkReducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_PASSWORD_RESET_LINK_REQUEST:
            return { loading: true }

        case ADMIN_PASSWORD_RESET_LINK_SUCCESS:
            return { loading: false, success: true, reset_link: action.payload }

        case ADMIN_PASSWORD_RESET_LINK_FAIL:
            return { loading: false, error: action.payload }

        case ADMIN_PASSWORD_LINK_RESET:
            return {}
        
        default:
            return state
    }

 }

 export const adminPasswordResetReducer = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_PASSWORD_RESET_REQUEST:
            return { loading: true }

        case ADMIN_PASSWORD_RESET_SUCCESS:
            return { loading: false, success: true, reset_password: action.payload }

        case ADMIN_PASSWORD_RESET_FAIL:
            return { loading: false, error: action.payload }

        case ADMIN_PASSWORD_RESET:
            return {}
        
        default:
            return state
    }

 }