import "./styles/global.scss";
import { useEffect } from "react";
import { Fragment } from "react";
import Error404 from "./components/Error/Error404";
import { Routes, Route } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import MainDash from "./Pages/MainDash/MainDash";
import Users from "./Pages/Users/Users";
import Orders from "./Pages/Orders/Orders";
import AdminLogin from "./components/Admin/AdminLogin";
import PriceEvaluation from "./Pages/Evaluation/PriceEvaluation";
import AdminForgotPasswordEmail from "./components/Admin/AdminForgotPasswordEmail";
import PrivateRoutes from "./utils/PrivateRoutes";
import CreateCustomerCRM from "./Forms/CustomerCRM/CreateCustomerCRM";
import { UpdateCustomerCRM } from "./Forms/CustomerCRM/UpdateCustomerCRM";
import MainDashOrderForm from "./Forms/MainDashOrderForm";
import { OrderTableView } from "./Pages/Orders/OrderTableView/OrderTableView";

function App() {
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  //     navigator.serviceWorker.register('/service-worker.js')
  //       .then(function(registration) {
  //         console.log('ServiceWorker registration successful with scope: ', registration.scope);
  //       })
  //       .catch(function(error) {
  //         console.error('ServiceWorker registration failed: ', error);
  //       });
  //   }
  // }, []);

  return (
    <Fragment>
      <Routes>
        <Route>
          <Route path="/crm/login" element={<AdminLogin />} />
          <Route
            path="/crm/admin-forgot-password-email"
            element={<AdminForgotPasswordEmail />}
          />
        </Route>
        <Route
          path="/*"
          element={
            <div className="App">
              <div className="AppGlass">
                <Sidebar />
                <main>
                  <Routes>
                    <Route element={<PrivateRoutes />}>
                      <Route path="/" element={<MainDash />} />
                      <Route path="/crm" element={<Orders />} />
                      <Route path="/crm/users" element={<Users />} />
                      <Route path="/crm" element={<Orders />} />
                      <Route
                        path="/crm/order-view/:id"
                        element={<OrderTableView />}
                      />
                      {/* <Route path="/crm/suborders" element={<SubHeaderRowGroupTitle />} /> */}
                      <Route
                        path="/crm/update/:id"
                        element={<CreateCustomerCRM />}
                      />
                      <Route
                        path="/crm/order-update/:id"
                        element={<UpdateCustomerCRM />}
                      />
                      <Route
                        path="/crm/device/update/:id"
                        element={<MainDashOrderForm />}
                      />
                      <Route
                        path="/crm/price_evaluation"
                        element={<PriceEvaluation />}
                      />

                      <Route path="/crm/*" element={<Error404 />} exact />
                    </Route>
                  </Routes>
                </main>
              </div>
            </div>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
