import axios from "axios"
import { BASE_URL } from "./apiConfig"
import {
    USERS_LIST_REQUEST,
    USERS_LIST_SUCCESS,
    USERS_LIST_FAIL
} from "../constants/userConstant"


export const listUsers = (pageNumber) => async (dispatch, getState) =>{

    try{
        dispatch({
            type: USERS_LIST_REQUEST
        })

        const {
            adminLogin: { admin }
        } = getState()

        const config = {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${admin.token}`
             },
           }
        
        const result = await axios.get(`/api/v1/crm/users/?page=${pageNumber}`, config)
        
        dispatch({
            type : USERS_LIST_SUCCESS, 
            payload:result.data
        })
        
    }
    catch(error){
        dispatch({
            type: USERS_LIST_FAIL,
            payload:
               error.response && error.response.data.detail
                  ? error.response.data.detail
                  : error.message,
           })
    }

}