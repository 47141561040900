export const USER_ADMIN_LOGIN_REQUEST = 'USER_ADMIN_LOGIN_REQUEST'
export const USER_ADMIN_LOGIN_SUCCESS = 'USER_ADMIN_LOGIN_SUCCESS'
export const USER_ADMIN_LOGIN_FAIL = 'USER_ADMIN_LOGIN_FAIL'
export const USER_ADMIN_LOGIN_RESET = 'USER_ADMIN_LOGIN_RESET'
export const USER_ADMIN_LOGOUT = 'USER_ADMIN_LOGOUT'

export const ADMIN_PASSWORD_RESET_LINK_REQUEST = 'ADMIN_PASSWORD_RESET_LINK_REQUEST'
export const ADMIN_PASSWORD_RESET_LINK_SUCCESS = 'ADMIN_PASSWORD_RESET_LINK_SUCCESS'
export const ADMIN_PASSWORD_RESET_LINK_FAIL = 'ADMIN_PASSWORD_RESET_LINK_FAIL'
export const ADMIN_PASSWORD_LINK_RESET = 'ADMIN_PASSWORD_LINK_RESET'


export const ADMIN_PASSWORD_RESET_REQUEST = 'ADMIN_PASSWORD_RESET_REQUEST'
export const ADMIN_PASSWORD_RESET_SUCCESS = 'ADMIN_PASSWORD_RESET_SUCCESS'
export const ADMIN_PASSWORD_RESET_FAIL = 'ADMIN_PASSWORD_RESET_FAIL'
export const ADMIN_PASSWORD_RESET = 'ADMIN_PASSWORD_RESET'


export const USER_ADMIN_LOGOUT_REQUEST = 'USER_ADMIN_LOGOUT_REQUEST'
export const USER_ADMIN_LOGOUT_SUCCESS = 'USER_ADMIN_LOGOUT_SUCCESS'
export const USER_ADMIN_LOGOUT_FAIL = 'USER_ADMIN_LOGOUT_FAIL'
export const USER_ADMIN_LOGOUT_RESET = 'USER_ADMIN_LOGOUT_RESET'