import UserTable from "./table/UserTable";
import { useSelector } from "react-redux";
import "./Users.scss";

export default function Users() {
  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;

  return (
    <>
      {admin.isAdmin && (
        <div className="users">
          <UserTable />
        </div>
      )}
    </>
  );
}
