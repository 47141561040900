import { useEffect, useState } from "react";
import "./UserTable.scss";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import {
  Checkbox,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  Table,
  TableRow,
  Paper,
  Pagination,
  Stack,
} from "@mui/material";
import { Typography } from "antd";
import { PropagateLoader } from "react-spinners";
import { listUsers } from "../../../actions/userDataAction";

const makeStyle = (value) => {
  if (value === true) {
    return {
      background: "rgb(145 254 159 / 47%)",
      color: "green",
    };
  } else if (value === false) {
    return {
      background: "#ffadad8f",
      color: "red",
    };
  } else {
    return {
      background: "#59bfff",
      color: "white",
    };
  }
};

export default function UserTable({ currentPage }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const userList = useSelector((state) => state.userList);
  const { users, loading: isLoading, error, success } = userList;
  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;

  useEffect(() => {
    if (admin.isAdmin) {
      dispatch(listUsers(page));
    }
  }, [dispatch, page, admin]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="userTable">
        <Container>
          {isLoading && (
            <div className="spinner-container">
              <PropagateLoader color="#36d7b7" />
            </div>
          )}
          {error && (
            <div className="error-container">
              <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
              </Alert>
            </div>
          )}
          {success && (
            <>
              {/* <Typography.Title level={4}>User List</Typography.Title> */}
              <TableContainer className="scrollable-content" component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell align="left">EMAIL ADDRESS</TableCell>
                      <TableCell align="left">PHONE NUMBER</TableCell>
                      <TableCell align="left">STAFF STATUS</TableCell>
                      <TableCell align="left">DELETION STATUS</TableCell>
                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ color: "white" }}>
                    {users?.user_list.map((user) => (
                      <TableRow
                        key={user.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" />
                        </TableCell>
                        <TableCell align="left">{user?.name}</TableCell>
                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">{user?.phone_number}</TableCell>
                        <TableCell align="left">
                          <span style={makeStyle(user.staff_status)}>
                            {user?.staff_status.toString()}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <span style={makeStyle(user.is_deleted)}>
                            {user?.is_deleted.toString()}
                          </span>
                        </TableCell>
                        <TableCell align="left" className="details">
                          Detail
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div>
                <Stack spacing={1}>
                  <Pagination
                    count={users?.total_page}
                    page={page}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </>
          )}
        </Container>
      </div>
    </>
  );
}
