import axios from "axios";
import { BASE_URL } from "./apiConfig";
import {
  USER_ADMIN_LOGIN_REQUEST,
  USER_ADMIN_LOGIN_SUCCESS,
  USER_ADMIN_LOGIN_FAIL,
  USER_ADMIN_LOGIN_RESET,
  USER_ADMIN_LOGOUT,
  ADMIN_PASSWORD_RESET_LINK_REQUEST,
  ADMIN_PASSWORD_RESET_LINK_SUCCESS,
  ADMIN_PASSWORD_RESET_LINK_FAIL,
  ADMIN_PASSWORD_RESET_REQUEST,
  ADMIN_PASSWORD_RESET_SUCCESS,
  ADMIN_PASSWORD_RESET_FAIL,
  USER_ADMIN_LOGOUT_REQUEST,
  USER_ADMIN_LOGOUT_SUCCESS,
} from "../constants/adminUserConstants";
import { saveAuthData } from "./auth";

export const admin_login = (phone_number, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADMIN_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/crm/admin_login/`,
      {
        phone_number,
        password,
      },
      config
    );

    dispatch({
      type: USER_ADMIN_LOGIN_SUCCESS,
      payload: data,
    });
    saveAuthData(data);
    // localStorage.setItem("adminInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_ADMIN_LOGIN_FAIL,
      payload: error.response
        ? error.response.data.message || error.response
        : error.message,
      // payload:
      //   error.response && error.response.data.detail
      //     ? error.response.data.detail
      //     : error.message,
    });
  }
};

export const admin_logout = () => async (dispatch) => {
  try {
    dispatch({
      type: USER_ADMIN_LOGOUT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(`/api/v1/crm/admin_logout/`, config);

    dispatch({
      type: USER_ADMIN_LOGOUT_SUCCESS,
    });
    dispatch({
      type: USER_ADMIN_LOGIN_RESET,
    });
    // localStorage.setItem("adminInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_ADMIN_LOGIN_FAIL,
      payload: error.response
        ? error.response.data.message || error.response
        : error.message,
      // payload:
      //   error.response && error.response.data.detail
      //     ? error.response.data.detail
      //     : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("adminInfo");
  dispatch({ type: USER_ADMIN_LOGOUT });
};

export const adminPasswordResetEmailAction =
  (phoneNumber) => async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_PASSWORD_RESET_LINK_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/v1/crm/admin_forgot_password/`,
        {
          phoneNumber,
        },
        config
      );

      dispatch({
        type: ADMIN_PASSWORD_RESET_LINK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_PASSWORD_RESET_LINK_FAIL,
        payload: error.response
          ? error.response.data.message || error.response
          : error.message,
      });
    }
  };

export const adminPasswordResetAction = (id, password) => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PASSWORD_RESET_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/crm/admin_change_password/${id}/`,
      {
        password,
      },
      config
    );

    dispatch({
      type: ADMIN_PASSWORD_RESET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PASSWORD_RESET_FAIL,
      payload: error.response
        ? error.response.data.message || error.response
        : error.message,
    });
  }
};
