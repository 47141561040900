export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST"
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS"
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL"
export const ORDER_LIST_RESET = "ORDER_LIST_RESET"

export const MAIN_PICKUP_ORDER_LIST_REQUEST = 'MAIN_PICKUP_ORDER_LIST_REQUEST'
export const MAIN_PICKUP_ORDER_LIST_SUCCESS = 'MAIN_PICKUP_ORDER_LIST_SUCCESS'
export const MAIN_PICKUP_ORDER_LIST_FAIL = 'MAIN_PICKUP_ORDER_LIST_FAIL'
export const MAIN_PICKUP_ORDER_LIST_RESET = 'MAIN_PICKUP_ORDER_LIST_RESET' 

export const ORDER_DETAIL_REQUEST = "ORDER_DETAIL_REQUEST"
export const ORDER_DETAIL_SUCCESS = "ORDER_DETAIL_SUCCESS"
export const ORDER_DETAIL_FAIL = "ORDER_DETAIL_FAIL"
export const ORDER_DETAIL_RESET = "ORDER_DETAIL_RESET"

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST"
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS"
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL"
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET'

export const ORDER_PICKER_LIST_REQUEST = "ORDER_PICKER_LIST_REQUEST"
export const ORDER_PICKER_LIST_SUCCESS = "ORDER_PICKER_LIST_SUCCESS"
export const ORDER_PICKER_LIST_FAIL = "ORDER_PICKER_LIST_FAIL"
export const ORDER_PICKER_LIST_RESET = "ORDER_PICKER_LIST_RESET"

export const TELLECALLER_LIST_REQUEST = "TELLECALLER_LIST_REQUEST"
export const TELLECALLER_LIST_SUCCESS = "TELLECALLER_LIST_SUCCESS"
export const TELLECALLER_LIST_FAIL = "TELLECALLER_LIST_FAIL"
export const TELLECALLER_LIST_RESET = "TELLECALLER_LIST_RESET"

export const DOCUMENT_LIST_REQUEST = "DOCUMENT_LIST_REQUEST"
export const DOCUMENT_LIST_SUCCESS = "DOCUMENT_LIST_SUCCESS"
export const DOCUMENT_LIST_FAIL = "DOCUMENT_LIST_FAIL"
export const DOCUMENT_LIST_RESET = "DOCUMENT_LIST_RESET"