import axios from "axios";
import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  MAIN_PICKUP_ORDER_LIST_FAIL,
  MAIN_PICKUP_ORDER_LIST_REQUEST,
  MAIN_PICKUP_ORDER_LIST_SUCCESS,
  ORDER_PICKER_LIST_REQUEST,
  ORDER_PICKER_LIST_SUCCESS,
  ORDER_PICKER_LIST_FAIL,
  ORDER_DETAIL_FAIL,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  TELLECALLER_LIST_REQUEST,
  TELLECALLER_LIST_SUCCESS,
  TELLECALLER_LIST_FAIL,
  DOCUMENT_LIST_FAIL,
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_SUCCESS,
} from "../constants/pickupOrderConstant";

export const pickupOrderList =
  (getOrderParams) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_REQUEST,
      });

      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };

      // Customize the endpoint URL based on the urlParams object
      let endpointURL = `/api/v1/crm/pickuporders/`;

      const { pageNumber, status, staffName, telleCaller, startDate, endDate } =
        getOrderParams;

      if (pageNumber && startDate && endDate) {
        endpointURL += `?page=${pageNumber}&start_date=${startDate}&end_date=${endDate}`;

        if (status) endpointURL += `&status=${status}`;
        else if (telleCaller) endpointURL += `&tellecaller=${telleCaller}`;
        else if (staffName) endpointURL += `&staff_name=${staffName}`;
      } else if (status && pageNumber) {
        endpointURL += `?page=${pageNumber}&status=${status}`;
      } else if (staffName && pageNumber) {
        endpointURL += `?page=${pageNumber}&staff_name=${staffName}`;
      } else if (telleCaller && pageNumber) {
        endpointURL += `?page=${pageNumber}&tellecaller=${telleCaller}`;
      } else if (pageNumber) {
        endpointURL += `?page=${pageNumber}`;
      } else {
        endpointURL += "/";
      }

      const { data } = await axios.get(endpointURL, config);

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const mainOrderListAction =
  (getOrderParams) => async (dispatch, getState) => {
    try {
      dispatch({ type: MAIN_PICKUP_ORDER_LIST_REQUEST });

      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin.token}`,
        },
      };
      let endpointURL = `/api/v1/crm/main_pickuporder`;
      const { pageNumber, search } = getOrderParams;

      if (pageNumber && search) {
        endpointURL += `/?page=${pageNumber}&search=${search}`;
      } else if (pageNumber) {
        endpointURL += `/?page=${pageNumber}`;
      } else {
        endpointURL += "/";
      }

      const { data } = await axios.get(endpointURL, config);

      dispatch({
        type: MAIN_PICKUP_ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: MAIN_PICKUP_ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const pickupOrederDetail = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAIL_REQUEST });

    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/v1/crm/pickuporders/${id}/`,
      config
    );
    dispatch({
      type: ORDER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const pickupOrderUpdate =
  (postData, id) =>
  async (dispatch, getState) => {
    const { final_price, imei_1, imei_2, device_color} = postData;
    const deviceID = device_color?.id || null

    try {
      dispatch({ type: ORDER_UPDATE_REQUEST });

      const {
        adminLogin: { admin },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const requestBody = {
        final_price,
        imei: imei_1,
        imei_2,
        device_color: deviceID,
      };

      const { data } = await axios.put(
        `/api/v1/crm/pickuporders/${id}/`,
        requestBody,
        config
      );
      dispatch({
        type: ORDER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const orderPickurList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_PICKER_LIST_REQUEST });

    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/v1/crm/order_pickur/`,
      config
    );

    dispatch({
      type: ORDER_PICKER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_PICKER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const tellecallerList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TELLECALLER_LIST_REQUEST });

    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/v1/crm/telle_callers/`,
    );

    dispatch({
      type: TELLECALLER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TELLECALLER_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const documentListAction = () => async (dispatch) => {
  try {
    dispatch({ type: DOCUMENT_LIST_REQUEST });

   
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.get(
      `/api/v1/crm/document_list/`,
      config
    );

    dispatch({
      type: DOCUMENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCUMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
