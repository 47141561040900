import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const adminLogin = useSelector((state) => state.adminLogin);
  const { admin } = adminLogin;
  
  return (
    <>
      {admin ? <Outlet /> : <Navigate to="/crm/login" />}
    </>
  );
};

export default PrivateRoutes;
