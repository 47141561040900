import React, { useEffect } from "react";
import { Typography } from "antd";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { RETURNOPTIONS } from "../../Data/Data";
import { MDBInput } from "mdb-react-ui-kit";

export const CustomerUpdateInput = ({ crm_info_details, control, errors }) => {
  return (
    <>
      {crm_info_details && (
        <div className="order-form-input">
          <Typography.Title level={4}>User Information</Typography.Title>
          <div className="basic-information">
            <div className="user">
              <MDBInput
                wrapperClass="mb-4"
                type="text"
                value={crm_info_details?.name_on_document || ""}
                label="name"
                name="name"
                disabled
              />
            </div>
            <div className="phone-number">
              <MDBInput
                wrapperClass="mb-4"
                type="text"
                value={crm_info_details?.customer_mobile || ""}
                label="phone_number"
                name="phone_number"
                disabled
              />
            </div>
          </div>

          <Typography.Title level={4}>Device Information</Typography.Title>

          <div className="device-information">
            <div className="device-model">
              <MDBInput
                wrapperClass="mb-4"
                type="text"
                value={crm_info_details?.product_model || ""}
                label="device_model"
                name="device_model"
                disabled
              />
            </div>

            <div className="device-brand">
              <MDBInput
                wrapperClass="mb-4"
                type="text"
                value={crm_info_details?.product_brand || ""}
                label="device_brand"
                name="device_brand"
                disabled
              />
            </div>
          </div>

          <Typography.Title level={4}>Device Cost</Typography.Title>
          <div className="price-related-input">
            <div className="final-price">
              <MDBInput
                wrapperClass="mb-4"
                type="text"
                value={crm_info_details?.final_price || ""}
                label="final_price"
                name="final_price"
                disabled
              />
            </div>
          </div>
          <Typography.Title level={4}>Reason Of Return Device</Typography.Title>
          <div className="device-return">
              <Controller
                control={control}
                id="device_return"
                name="device_return"
                render={({ field }) => (
                  <Select
                    isClearable={true}
                    options={RETURNOPTIONS}
                    placeholder="Select Device Return Options.."
                    menuPortalTarget={document.body}
                    {...field}
                    onChange={(value) => field.onChange(value)}
                    // className={errors.payment ? "input-error" : ""}
                  />
                )}
              />
            </div>
        </div>
      )}
    </>
  );
};
