import {
  SELLER_INFO_UPDATE_REQUEST,
  SELLER_INFO_UPDATE_SUCCESS,
  SELLER_INFO_UPDATE_FAIL,
  SELLER_INFO_UPDATE_RESET,
  CRM_INFO_LIST_FAIL,
  CRM_INFO_LIST_REQUEST,
  CRM_INFO_LIST_RESET,
  CRM_INFO_LIST_SUCCESS,
  CRM_INFO_DETAILS_FAIL,
  CRM_INFO_DETAILS_REQUEST,
  CRM_INFO_DETAILS_SUCCESS,
  CRM_INFO_DETAILS_RESET,
  DOCUMENT_GET_LIST_REQUEST,
  DOCUMENT_GET_LIST_SUCCESS,
  DOCUMENT_GET_LIST_FAIL,
  DOCUMENT_GET_LIST_RESET,
  PDF_DOWNLOAD_FAIL,
  PDF_DOWNLOAD_REQUEST,
  PDF_DOWNLOAD_SUCCESS,
  CRM_INFO_UPDATE_FAIL,
  CRM_INFO_UPDATE_REQUEST,
  CRM_INFO_UPDATE_RESET,
  CRM_INFO_UPDATE_SUCCESS,
} from "../constants/sellerInfoConstant";

export const updateSellerInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_INFO_UPDATE_REQUEST:
      return { loading: true };

    case SELLER_INFO_UPDATE_SUCCESS:
      return { loading: false, success: true, seller_info: action.payload };

    case SELLER_INFO_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case SELLER_INFO_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const crmInfoListReducer = (state = { crm_info: [] }, action) => {
  switch (action.type) {
    case CRM_INFO_LIST_REQUEST:
      return { loading: true, crm_info: [] };

    case CRM_INFO_LIST_SUCCESS:
      return { loading: false, success: true, crm_info: action.payload };

    case CRM_INFO_LIST_FAIL:
      return { loading: false, error: action.payload };

    case CRM_INFO_LIST_RESET:
      return {};

    default:
      return state;
  }
};

export const crmInfoDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case CRM_INFO_DETAILS_REQUEST:
      return { loading: true };

    case CRM_INFO_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        crm_info_details: action.payload,
      };

    case CRM_INFO_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    case CRM_INFO_DETAILS_RESET:
      return {};

    default:
      return state;
  }
};

export const updateCRMReducer = (state = {}, action) => {
  switch (action.type) {
    case CRM_INFO_UPDATE_REQUEST:
      return { loading: true };

    case CRM_INFO_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        update_crm_info: action.payload,
      };

    case CRM_INFO_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case CRM_INFO_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};

export const documentListReducer = (state = { document_list: [] }, action) => {
  switch (action.type) {
    case DOCUMENT_GET_LIST_REQUEST:
      return { loading: true, document_list: [] };

    case DOCUMENT_GET_LIST_SUCCESS:
      return { loading: false, success: true, document_list: action.payload };

    case DOCUMENT_GET_LIST_FAIL:
      return { loading: false, error: action.payload };

    case DOCUMENT_GET_LIST_RESET:
      return { document_list: [] };

    default:
      return state;
  }
};

export const pdfDownloadReducer = (state = {}, action) => {
  switch (action.type) {
    case PDF_DOWNLOAD_REQUEST:
      return { loading: true };
    case PDF_DOWNLOAD_SUCCESS:
      return { loading: false, success: true };
    case PDF_DOWNLOAD_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
