import axios from 'axios';
import {
    DEVICE_REPORT_DETAIL_REQUEST,
    DEVICE_REPORT_DETAIL_SUCCESS,
    DEVICE_REPORT_DETAIL_FAIL,

    DEVICE_REPORT_UPDATE_REQUEST,
    DEVICE_REPORT_UPDATE_SUCCESS,
    DEVICE_REPORT_UPDATE_FAIL,

    DEVICE_COLOR_LIST_REQUEST,
    DEVICE_COLOR_LIST_SUCCESS,
    DEVICE_COLOR_LIST_FAIL,

} from '../constants/deviceReportConstant'


export const deviceColorListAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEVICE_COLOR_LIST_REQUEST });

    const {
      adminLogin: { admin },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${admin?.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/v1/crm/device_color_list/`,
      config
    );
    dispatch({
      type: DEVICE_COLOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DEVICE_COLOR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const deviceDetailAction = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEVICE_REPORT_DETAIL_REQUEST });
  
      const {
        adminLogin: { admin },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/v1/crm/detail/${id}/`,
        config
      );
      dispatch({
        type: DEVICE_REPORT_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEVICE_REPORT_DETAIL_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
  export const deviceUpdateAction = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEVICE_REPORT_UPDATE_REQUEST });
  
      const {
        adminLogin: { admin },
      } = getState();
  
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${admin?.token}`,
        },
      };
      const { data } = await axios.update(`/api/v1/crm/update/${id}`, config);
      dispatch({
        type: DEVICE_REPORT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DEVICE_REPORT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };