import {
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_RESET,

  MAIN_PICKUP_ORDER_LIST_FAIL,
  MAIN_PICKUP_ORDER_LIST_SUCCESS,
  MAIN_PICKUP_ORDER_LIST_REQUEST,
  MAIN_PICKUP_ORDER_LIST_RESET,

  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_RESET,
  ORDER_PICKER_LIST_REQUEST,
  ORDER_PICKER_LIST_SUCCESS,
  ORDER_PICKER_LIST_FAIL,
  TELLECALLER_LIST_REQUEST,
  TELLECALLER_LIST_SUCCESS,
  TELLECALLER_LIST_FAIL,
  DOCUMENT_LIST_REQUEST,
  DOCUMENT_LIST_FAIL,
  DOCUMENT_LIST_SUCCESS,
  DOCUMENT_LIST_RESET,
  ORDER_DETAIL_RESET
} from "../constants/pickupOrderConstant";
import { DOCUMENT_GET_LIST_FAIL } from "../constants/sellerInfoConstant";

export const listOrderReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true, orders: [] };

    case ORDER_LIST_SUCCESS:
      return { loading: false, success: true, orders: action.payload };

    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case ORDER_LIST_RESET:
      return { orders: [] }

    default:
      return state;
  }
};

export const detailOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return { loading: true };

    case ORDER_DETAIL_SUCCESS:
      return { loading: false, success: true, order_detail: action.payload };

    case ORDER_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    
    case ORDER_DETAIL_RESET:
      return { loading: false, success: null, error: null, order_detail: {}}

    default:
      return state;
  }
};

export const updateOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loading: true };

    case ORDER_UPDATE_SUCCESS:
      return { loading: false, success: true, order_update: action.payload };

    case ORDER_UPDATE_FAIL:
      return { loading: false, error: action.payload };

    case ORDER_UPDATE_RESET:
      return {};

    default:
      return state;
  }
};


export const mainOrderListReducer = (state = { main_orders: [] }, action) => {
  switch (action.type) {
    case MAIN_PICKUP_ORDER_LIST_REQUEST:
      return { loading: true, main_orders: [] };

    case MAIN_PICKUP_ORDER_LIST_SUCCESS:
      return { loading: false, success: true, main_orders: action.payload };

    case MAIN_PICKUP_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };

    case MAIN_PICKUP_ORDER_LIST_RESET:
      return { main_orders: [] }

    default:
      return state;
  }
};


export const listOrderPickurReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PICKER_LIST_REQUEST:
      return { loading: true };

    case ORDER_PICKER_LIST_SUCCESS:
      return { loading: false, success: true, order_pickers: action.payload };

    case ORDER_PICKER_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const listTelleCallerReducer = (state = {}, action) => {
    switch (action.type) {
      case TELLECALLER_LIST_REQUEST:
        return { loading: true };
  
      case TELLECALLER_LIST_SUCCESS:
        return { loading: false, success: true, tellecallers: action.payload };
  
      case TELLECALLER_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      default:
        return state;
    }
  };

  export const documentListReducer = (state = { documents: [] }, action) => {
    switch (action.type) {
      case DOCUMENT_LIST_REQUEST:
        return { loading: true, documents: [] };
  
      case DOCUMENT_LIST_SUCCESS:
        return { loading: false, success: true, orders: action.payload };
  
      case DOCUMENT_LIST_FAIL:
        return { loading: false, error: action.payload };
  
      case DOCUMENT_LIST_RESET:
        return { documents: [] }
  
      default:
        return state;
    }
  };
  