import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Alert } from "react-bootstrap";
import { deviceColorListAction } from "../actions/deviceReportAction";
import CustomerFormInfo from "./FormInput/CustomerFormInfo";
import MainOrderFormInput from "./FormInput/MainOrderFormInput";
import "./Form.scss";
import {
  sellerDocumentListAction,
} from "../actions/sellerInfoAction";
import { PropagateLoader } from "react-spinners";
import { MDBBtn } from "mdb-react-ui-kit";
import { pickupOrederDetail } from "../actions/pickupOrderAction";

export default function MainDashOrderForm() {
  const dispatch = useDispatch();
  const sellerDocumentList = useSelector((state) => state.sellerDocumentList);
  const { document_list } = sellerDocumentList;
  const orderDetail = useSelector((state) => state.orderDetail);
  const { order_detail, loading: isLoading, error: oError } = orderDetail;
  const device_colors_list = useSelector((state) => state.deviceColors);
  const { device_colors } = device_colors_list;
  const { id } = useParams();
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      final_price: "",
      device_color: null,
      imei_1: "",
      imei_2: "",
    },
  });

  useEffect(() => {
    if (order_detail && device_colors) {
      const { device_color, final_price, imei, imei_2 } = order_detail;
      const selectedColor = device_colors.find(
        (color) => color.name === device_color
      );

      const formValue = {
        final_price: final_price || "",
        device_color: selectedColor || "",
        imei_1: imei || "",
        imei_2: imei_2 || "",
      };
      for (const value in formValue) {
        setValue(value, formValue[value]);
      }
    }
  }, [order_detail, setValue, device_colors]);

  //   useEffect(() => {
  //     if (order_update) {
  //       setTimeout(() => {
  //         navigate("/", { replace: true });
  //       }, 1000);
  //     }
  //     dispatch({ type: ORDER_UPDATE_RESET });
  //   }, [order_update, navigate, dispatch]);

  useEffect(() => {
    dispatch(deviceColorListAction());
    dispatch(sellerDocumentListAction());
    dispatch(pickupOrederDetail(id));
  }, [id, dispatch]);

  const orderSubmitForm = (data) => {
    // const postData = {};
    const postData = {
      documents: [],
    };

    for (const key in data) {
      if (key.startsWith("document_")) {
        const documentIndex = parseInt(key.split("_")[1]);
        const documentValue = data[key];
        if (documentValue !== undefined) {
          const documentNameKey = `document_name_${documentIndex}`;
          const documentImageKey = `document_image${documentIndex}`;

          const documentNameValue = data[documentNameKey];
          const documentImageValue = data[documentImageKey];
          postData.documents[documentIndex] = {
            doucument: documentValue.id,
            // name: documentValue.name,
            name_on_document: documentNameValue,
            doucment_image: documentImageValue,
          };
        }
      } else {
        if (data[key]) {
          postData[key] = data[key];
        }
      }
    }
   
    // dispatch(createSellerInfoAction(postData, id));
    // dispatch(pickupOrderUpdate(postData, id));
  };

  return (
    <>
      <div className="order-form">
        {isLoading && (
          <div className="loading-container">
            <PropagateLoader color="#36d7b7" />
          </div>
        )}
        {oError && (
          <div className="error-container">
            <Alert variant="danger" style={{ backgroundColor: "#ffffff" }}>
              <Alert.Heading>Error</Alert.Heading>
              <p>{oError}</p>
            </Alert>
          </div>
        )}
        {order_detail && (
          <>
            {/* <div className="device-title">
              <Typography.Title level={3}>
                Change Device Information
              </Typography.Title>
            </div> */}
            <Card>
              <form onSubmit={handleSubmit(orderSubmitForm)}>
                {/* <div className="custom-border"> */}
                <MainOrderFormInput
                  device_colors={device_colors}
                  order_detail={order_detail}
                  control={control}
                />
                <CustomerFormInfo
                  control={control}
                  document_list={document_list}
                />
                {/* </div> */}

                <div>
                  <MDBBtn
                    className="mt-4"
                    type="submit"
                    style={{ height: "2.5rem", width: "6rem" }}
                    block
                  >
                    Update
                  </MDBBtn>
                </div>
              </form>
            </Card>
          </>
        )}
      </div>
    </>
  );
}
