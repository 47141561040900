// export const getOrderParams = {
//     startDate: "",
//     endDate: "",
//     pageNumber: "",
//     staffName: "",
//     status: "",
//     telleCaller: ""
// }

let orderParams = {}; // Initial value

export const resetOrderParams = () => {
    orderParams = {};
  };
export const setOrderParams = (params) => {
    orderParams = { ...orderParams, ...params };
};

export const getOrderParams = () => {
    return { ...orderParams };
  };